@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Nunito:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ubuntu&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Niconne&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');
.animated-div {
    border-top: 1px solid #fff;
    font-family: 'Oswald', sans-serif;
    height: 350px;
    width: 100%;
    position: relative;
    overflow: hidden;
    background:rgb(0, 33, 65);
    background: -webkit-linear-gradient(to right,rgba(0, 33, 65, 0.9), rgb(0, 33, 65));
    background: linear-gradient(to right,rgba(0, 33, 65, 0.9), rgb(0, 33, 65));
    background-size: 200% 100%;
    animation: animatedBackground1 5s linear infinite;
    top:0%;
    display: flex;
    justify-content: center;
    color: white;
  }

  .box div {
    position: absolute;
    width: 30px;
    height: 30px;
    background-color: transparent;
    border: 6px solid rgba(255, 255, 255, 0.8);
  }
  
  .box div:nth-child(1) {
    top: 12%;
    left: 42%;
    animation: animate 10s linear infinite;
  }
  
  .box div:nth-child(2) {
    top: 70%;
    left: 50%;
    animation: animate 7s linear infinite;
  }
  
  .box div:nth-child(3) {
    top: 17%;
    left: 6%;
    animation: animate 9s linear infinite;
  }
  
  .box div:nth-child(4) {
    top: 20%;
    left: 60%;
    animation: animate 10s linear infinite;
  }
  
  .box div:nth-child(5) {
    top: 67%;
    left: 10%;
    animation: animate 6s linear infinite;
  }
  
  .box div:nth-child(6) {
    top: 80%;
    left: 70%;
    animation: animate 12s linear infinite;
  }
  
  .box div:nth-child(7) {
    top: 60%;
    left: 80%;
    animation: animate 15s linear infinite;
  }
  
  .box div:nth-child(8) {
    top: 32%;
    left: 25%;
    animation: animate 16s linear infinite;
  }
  
  .box div:nth-child(9) {
    top: 92%;
    left: 25%;
    animation: animate 9s linear infinite;
  }
  
  .box div:nth-child(10) {
    top: 20%;
    left: 80%;
    animation: animate 5s linear infinite;
  }
  
  @keyframes animate {
    0% {
      transform: scale(0) translateY(0) rotate(0);
      opacity: 1;
    }
  
    100% {
      transform: scale(1.3) translateY(-90px) rotate(360deg);
      opacity: 0;
    }
  }

  .verify-parent-container {
    display: flex;
    flex-wrap: wrap;
    width: 80%;
    left: 10%;
    position: relative;
    margin-top: 18px;
    justify-content: space-between;
   
    
}


.verify-a, .verify-b{

    width: 47%;
    height:400px;
    background-color: rgb(0, 33, 65);
    box-shadow: 15px 14px 10px #0000008c;
    border: 20px solid transparent;
    border-radius: 5px;
    font-family: 'Oswald', sans-serif;
}


@media only screen and (max-width: 600px) {
    .verify-a, .verify-b{
        width: 100%;
        font-size: 12px;
        margin-bottom: 20px;

    }
    .verify-a span{
        
        font-size: 30px;
        position: relative;
        left:30%
        
    }
    
}
.verify-container{

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 70px;
    border-radius: 8px;
  
  }
  .certificate-container {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Oswald'
  }
  .p-animate {
    font-size: 2.5rem;
    color: #222;
    color: #fff;
    position: absolute;

    top: 143px;
  }
  .showdiv2
  {
    display: flex;
    justify-content: center;
  }
  .p-status b{
    font-family: "Oswald";
    font-weight: 50;
  }

  .p-status {
    font-size: 150%;
    color: whitesmoke;
    width:42%;
  
    text-align: left;
    height: 99px;
    text-align: center;
    position: absolute;
    top: 125px;
    font-family: 'PlayFair';
  }
  
  .p-status1 {
    font-size: 160%;
    color: whitesmoke;
    width: 375px;
   
    text-align: left;
    height: 99px;
    text-align: center;
    position: absolute;
    top: 170px;
    font-family: 'Nunito';
  }
  


.h-style {
    font-size: 3.5rem;
    color: #fff;
    position: relative;
    
    text-align: center;
  }
  .arrow{
    position: absolute;
    top: 60%;
    left: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
 .arrow img{
    transform: rotate(-90deg);
    width:70px;
    height: 70px;
 }

  @keyframes borderAnimation {
    0% {
      box-shadow: 0 0 5px rgb(0, 33, 65);
    }
  
    50% {
      box-shadow: 0 0 20px rgb(0, 33, 65);
    }
  
    100% {
      box-shadow: 0 0 5px rgb(0, 33, 65);
    }
  }
  
  @keyframes borderAnimation1 {
    0% {
      box-shadow: 0 0 3px red;
    }
  
    50% {
      box-shadow: 0 0 18px red;
    }
  
    100% {
      box-shadow: 0 0 3px red;
    }
  }
  
  @keyframes borderAnimation2 {
    0% {
      box-shadow: 0 0 3px whitesmoke;
    }
  
    50% {
      box-shadow: 0 0 18px whitesmoke;
    }
  
    100% {
      box-shadow: 0 0 3px whitesmoke;
    }
  }

  .verify-description{
   width: 80%;
   margin-bottom: 50px;
  }
  .label {
    color: white;
    font-weight: bold;
    font-size: 30px;
    margin-bottom: 40px;
  }
  
  .input {
    padding: 12px;
    border: none;
    border-radius: 20px;
    margin-bottom: 30px;
    width: 55%;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
  }
  
  .button {
    background-color: #3A6073;
    color: white;
    padding: 12px 24px;
    border: none;
    border-radius: 7px;
    font-size: 18px;
    cursor: pointer;
  }
  
  @keyframes zoom {
    0% {
      transform: scale(1);
    }
  
    50% {
      transform: scale(1.2);
    }
  
    100% {
      transform: scale(1);
    }
  }

  @media only screen and (max-width: 680px) {
    .border-container {
      width: 80px;
      height: 25px;
      top: 75%;
    }
    .p-status{
      width: 100%;
      margin-top: 20px;
    }
  
    .border-container p {
      position: absolute;
      font-size: 10px;
      top: 12px;
      left: 12px;
    }
    .overlay-text{
      font-size: 20px;
    }

  }
  @media (max-width: 600px) {
    .arrow img {
        transform: rotate(0deg); /* Reset rotation for smaller screens */
        
          
          width:50px;
          height: 70px;
       
      
      }
    .arrow {
        top:25%;
        left:43%;
    }
}
