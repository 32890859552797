.container1 {
    
    margin: var(--m-auto);
    padding: 0 1rem;
    width: 76%;
    position: relative;
    left: 11%;
    
  }

  .section-heading1 {
    font-size: 4rem;
  }
 
  #section_counter1 {
    padding: 5rem 0;
  background-color: #162B6F;
  position: relative;
  width: 100%;
    margin-top: 10px;
  }
  #section_counter1 .section-heading1 {
    padding-bottom: 5rem;
    text-align: center;
    letter-spacing: 0.1rem;
  }
  .counter-grid1 {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
    gap: 2rem;
  }
  
  .counter-item1 {
    background: var(--bg-item);
    padding: 3rem 1.5rem;
    text-align: center;
    border-radius: 1rem;
    transition: all 0.5s ease-in-out;
    transform: translateY(6rem);
    color: white;
    background-color: #244183;
    border-bottom: 5px solid white;
  }
  .counter-img1 {
    width: 7rem;
    height: 7rem;
    object-fit: contain;
    filter: drop-shadow(0 0 0.2rem var(--bg-hero));
  }
  .counter-img1:nth-child(1) {
    width: 8rem;
    height: auto;
  }
  
  .counter1::after {
    content: attr(data-content);
  }
  
  
  .counter-item1 .counter1 {
    font-size: 4.5rem;
   
    letter-spacing: 0.5rem;
    -webkit-text-stroke-width: 0.15rem;
    -moz-text-stroke-width: 0.15rem;
    -ms-text-stroke-width: 0.15rem;
    -o-text-stroke-width: 0.15rem;
    -webkit-text-stroke-color: var(--white);
    -moz-text-stroke-color: var(--white);
    -ms-text-stroke-color: var(--white);
    -o-text-stroke-color: var(--white);
  }
  
  @keyframes slide-up {
    0% {
      transform: translateY(6rem);
    }
    100% {
      transform: translateY(0rem);
    }
  }