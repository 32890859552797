.footer-centered-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50%;
    margin-top:20px;
    color:#162B6F;
  
}
  
  .footer-centered-text {
    text-align: center;
    margin: 20px 0;
  }
  .footer-centered-text1 {
    text-align: center;
    margin: 20px 0;
    font-family: 'Oswald', sans-serif;
    font-size: 20px;
  }
  .footer-icon img{
    width: 15px;
  }
  .footer-icons-container {
    display: flex;
    justify-content: center;
  }
  
  .footer-icon {
    width: 50px; /* Adjust the width of each icon */
    height: 50px; /* Adjust the height of each icon */
    cursor: pointer;
    margin: 0 10px; /* Adjust the margin between icons */
    display: flex;
    align-items: center;
    justify-content: center;
    /* Additional styling for the icon */
  }
  
  @media only screen and (max-width: 768px) {
    .footer-icons-container {
      flex-direction: column;
      align-items: center;
    }
  }
  