.container {
    
  margin: var(--m-auto);
  padding: 0 1rem;
  width: 76%;
  position: relative;
  left: 11%;
  
}

.section-heading {
  font-size: 4rem;
}
#hero,
#pricing {
  background: var(--bg-hero);
  display: flex;
  align-items: center;
  justify-content: center;
}
#section_counter {
  padding: 5rem 0;
background-color: #162B6F;
position: relative;
width: 100%;
  margin-top: 10px;
  
}
#section_counter .section-heading {
  padding-bottom: 5rem;
  text-align: center;
  letter-spacing: 0.1rem;
}
.counter-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
  gap: 2rem;
}

.counter-item {
  background: var(--bg-item);
  padding: 3rem 1.5rem;
  text-align: center;
  border-radius: 1rem;
  transition: all 0.5s ease-in-out;
  transform: translateY(6rem);
  color: white;
  background-color: #244183;
  border-bottom: 5px solid white;
}
.counter-img {
  width: 7rem;
  height: 7rem;
  object-fit: contain;
  filter: drop-shadow(0 0 0.2rem var(--bg-hero));
}
.counter-img:nth-child(1) {
  width: 8rem;
  height: auto;
}

.counter::after {
  content: attr(data-content);
}


.counter-item .counter {
  font-size: 4.5rem;
 
  letter-spacing: 0.5rem;
  -webkit-text-stroke-width: 0.15rem;
  -moz-text-stroke-width: 0.15rem;
  -ms-text-stroke-width: 0.15rem;
  -o-text-stroke-width: 0.15rem;
  -webkit-text-stroke-color: var(--white);
  -moz-text-stroke-color: var(--white);
  -ms-text-stroke-color: var(--white);
  -o-text-stroke-color: var(--white);
}

@keyframes slide-up {
  0% {
    transform: translateY(6rem);
  }
  100% {
    transform: translateY(0rem);
  }
}