.responsive-map{
    overflow: hidden;
    height: 0;
    padding-bottom: 30%;
    position: relative;
    width: 93.1%;
    left: 3.5%;
}

.responsive-map iframe{
    border:0;
    height: 100%;
    width: 100%;
    left:0;
    top:0;
    position: absolute;
}
